//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      document.getElementsByClassName("hp-content")[0].style.paddingBottom =
        "9rem";
    }
  },
};
